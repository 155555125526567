import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../components/PageBanner';
import Offres from '../components/Offres';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={4} title={"Nos offres"} description={"Prestations"} mdxType="PageBanner" />
    <Offres mdxType="Offres" />
    <section id="two">
      <div className="inner">
        <h2>{`Soumettez-nous vos besoins`}</h2>
        <p>{`Pour les associations à but non lucratif et les écoles, l'association fournit au cas par cas des prestations à des conditions particulières. `}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "mailto:contact@toulouse-dataviz.fr?subject=demande_de_renseignements"
            }}>{`contact@toulouse-dataviz.fr`}</a></li>
          <li parentName="ul">{`Alain Ottenheimer 06 03 24 11 87`}</li>
          <li parentName="ul">{`Alain Roan 06 19 65 53 83`}</li>
        </ul>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      